import { Box, Container, Grid, Typography } from '@mui/material';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import PageLoader from 'csam/components/common/PageLoader';
import DataProtectionTabs from 'csam/components/DataProtectionTabs';
import Footer from 'csam/components/Footer';
import Headerdark from 'csam/components/HeaderDark';
import LocaleContext from 'csam/components/LocaleContext';
import OrgStructure from 'csam/components/OrgStructure';
import TrainingCard from 'csam/components/TrainingCard';
import InternalServerError from 'csam/pages/InternalServerError';
import DataProtectionTranslations from 'csam/translations/DataProtectionTranslations';
import { fixed, getImage } from 'csam/utils/Constants';
import React, { useEffect } from 'react';

interface TrainingsData {
  id: number;
  title: string;
  link: string;
  image: string;
  status: number;
}

interface BannerData {
  id: number;
  sub_heading: string;
  heading: string;
  description: string;
  image: string;
  success: boolean;
}

interface OrganizationData {
  id: number;
  image: string;
}

interface GoodReadsData {
  id: number;
  title: string;
  link: string;
  image: string;
  status: number;
}

const DataProtection = () => {
  const { locale } = React.useContext(LocaleContext);
  const localeFromStorage = localStorage.getItem('locale') ?? 'en';

  const getTranslation = (key: keyof (typeof DataProtectionTranslations)['en']) =>
    DataProtectionTranslations[localeFromStorage as keyof typeof DataProtectionTranslations]?.[key] ||
    DataProtectionTranslations.en[key];


    const pageName = 'Data Protection';  
    const encodedPage = encodeURIComponent(pageName);  
    
    const mutation = useAuthenticatedMutation(['post-page-activity', 'PostData'], (postData) => ({    
      url: `${fixed}crud/user-page-landing-activity`,    
      method: 'POST',    
      headers: { 'Content-Type': 'application/json' },    
      data: postData,  
    }));  
    
    useEffect(() => {    
      const postData = {      pageName: pageName,    };    
      mutation.mutate(postData);  
    }, []);

  const { isPending, error, data } = useAuthenticatedQuery<TrainingsData>(['data_protection_trainings', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/data-protection-trainings`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const {
    isPending: bannerPending,
    error: bannerError,
    data: bannerData,
  } = useAuthenticatedQuery<BannerData>(['Banner', 'BannerData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/banner?page=${encodedPage}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const {
    isPending: orgImagePending,
    error: orgImageError,
    data: orgImageData,
  } = useAuthenticatedQuery<OrganizationData>(['orgStructure', 'orgData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/organization-structure`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const {
    isPending: goodReadsPending,
    error: goodReadsError,
    data: goodReadsData,
  } = useAuthenticatedQuery<GoodReadsData>(['goodReads', 'goodReadsData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/data-protection-god-reads`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (isPending) return <PageLoader />;
  if (error) return <Box>Error: {error.message}</Box>;
  if (!data || !data.success || !data.data || !Array.isArray(data.data)) return <InternalServerError />;

  if (bannerPending) return <PageLoader />;
  if (bannerError) return <Box>Error: {bannerError.message}</Box>;
  if (bannerData && !bannerData.success) return <InternalServerError />;
  
  if (orgImagePending) return <PageLoader />;
  if (orgImageError) return <Box>Error: {orgImageError.message}</Box>;
  if (orgImageData && !orgImageData.success) return <InternalServerError />;

  if (goodReadsPending) return <PageLoader />;
  if (goodReadsError) return <Box>Error: {goodReadsError.message}</Box>;
  if (goodReadsData && !goodReadsData.success) return <InternalServerError />;

  const orgImage = Array.isArray(orgImageData.data) && orgImageData.data.length > 0 ? orgImageData.data[0].image : null;

  const banner = bannerData.data;

  const trainings = data.data;

  const goodReads = goodReadsData.data;

  return (
    <Box className="bg-black">
      <Headerdark />
      <Container>
        <img
          className="img-responsive mx-auto d-block"
          style={{ width: '90%' }}
          src={`${getImage}banners/${banner.image}`}
          alt="Data Protection"
        />
      </Container>

      <Container sx={{ pt: 5 }}>
        <Box className="section_title data-pro-title" sx={{ mb: 4 }}>
          <Typography variant="h2"> {banner.heading} </Typography>
          <Typography variant="body1"> {banner.sub_heading} </Typography>
        </Box>
        <DataProtectionTabs />

        <Typography className="common_title common_data-pro-title mt-5" variant="commonTitle" component="h4">
          {getTranslation('heading2')}
        </Typography>

        <Box className="quick_tools">
          <Grid container spacing={4} sx={{ mt: 3 }}>
            {trainings.map((training) => (
              <TrainingCard key={training.id} training={training} getImage={getImage} />
            ))}
          </Grid>
        </Box>
      </Container>

      <OrgStructure orgImage={orgImage}  goodReads={goodReads} />

      <Footer />
    </Box>
  );
};
export default DataProtection;