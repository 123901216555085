import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';

const UsersLandingActivity = () => {
  const { locale, setLocale } = useContext(LocaleContext);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
  
    
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', filterable: false, width: 40, renderCell: (params) => params.value },
    {
      field: 'user_id',
      headerName: 'User ID',
      width: 100,
      renderCell: (params) => (params.row.user_id ? params.row.user_id.user_id : 'N/A'),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      renderCell: (params) => (params.row.user ? params.row.user.email : 'N/A'),
    },
    { field: 'page_name', headerName: 'Page Name', width: 200 },
    { field: 'created_at', headerName: 'Created At', width: 300 },
  ];
  interface GamesData {
    id: number;
    game_title: string;
    link: string;
    created_at: string;
    status: number;
  }
  interface GamesResponse {
    success: boolean;
    data: GamesData;
    translatableFields: string[];
  }
  const relations = ['user'];
  const relationsParam = relations.join(',');
  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<GamesResponse[]>(
    ['UserLandingPage', 'UserLandingPageData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/user_page_activites?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;
  return (
    <AdminWrapper>
      {' '}
      <Box sx={{ display: 'flex', gap: 2 }}>
        {' '}
        <Typography variant="h6" sx={{ mb: 3 }}>
          Users Page Landing Activites
        </Typography>{' '}
      </Box>{' '}
      <AdminDataGrid<GamesResponse> data={data} columns={columns} setSelectedIds={setSelectedIds}  />{' '}
    </AdminWrapper>
  );
};
export default UsersLandingActivity;
