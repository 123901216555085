import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import Lamp from 'csam/images/lamp.png';
import { fixed } from 'csam/utils/Constants';
import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

interface TipCard {
  id: number;
  tip_title: string;
  tip_description: string;
  video: string | null;
  video_title: string;
  status: number;
}

interface TipsCardsData {
  tipCard: TipCard[];
  success: boolean;
}

interface UseMyApiCallParams {
  activityName: string;
  deps: string[];
}

interface DataProtectionVideo {
  video: string;
  video_title: string;
}

const useMyApiCall = ({ activityName, deps }: UseMyApiCallParams) => {
  const body = React.useMemo(() => ({ activityName }), [activityName]);
  const x = useAuthenticatedQuery(
    activityName ? ['data-protection', activityName, ...deps] : [],
    activityName
      ? {
          url: `${fixed}activity/addPoints`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: body,
        }
      : {
          url: '',
          method: 'GET',
          headers: {},
          data: {},
        },
  );

  return { isPending: x.isPending, error: x.error, data: activityName ? x.data : null };
};

const DataProtectionTabs = () => {
  const { locale } = React.useContext(LocaleContext);
  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<TipsCardsData>(
    ['dataProtection', 'dataProtectionData', locale],
    {
      url: `${fixed}crud/${localeFromStorage || locale}/data-protection-weeks`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  const [hoverActivity, setHoverActivity] = React.useState('');
  const [videoActivity, setVideoActivity] = React.useState('');

  const { data: apiDataHover } = useMyApiCall({ activityName: hoverActivity, deps: [hoverActivity] });
  const { data: apiDataVideo } = useMyApiCall({ activityName: videoActivity, deps: [videoActivity] });

  React.useEffect(() => {
    if (apiDataHover && apiDataHover.success && apiDataHover.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      toast.success(apiDataHover.data?.message);
    }
  }, [apiDataHover]);

  React.useEffect(() => {
    if (apiDataVideo && apiDataVideo.success && apiDataVideo.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      toast.success(apiDataVideo.data?.message);
    }
  }, [apiDataVideo]);

  if (isPending) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error.message}</Box>;
  if (!data || !data.success || !data.data || !Array.isArray(data.data)) return <Box>Internal Server Error</Box>;

  const tipCard = data.data;

  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ width: '100%' }}>
      <ToastContainer />
      <Grid container spacing={2} className="month-cards pt-3">
        {tipCard.map((tip) => (
          <Grid data-testid="tip-card" item md={3} xs={12} key={tip.id} className="flex-item">
            <Box className="month_card">
              <Box
                className="cards_item"
                onMouseEnter={() => {
                  if (tip.status !== 0) {
                    setHoverActivity(`${currentYear}_${tip.id}_data_protection_hover_activity`);
                  } else {
                    setHoverActivity('');
                  }
                }}
              >
                <img src={tip.thumbnail || Lamp} alt="" />
                {tip.tip_title}
              </Box>
              <Box className="cards_item back">
                <Box className="lamp_back d-flex">
                  <Box className="flex-item">
                    <img src={Lamp} alt="" />
                  </Box>
                  <Box className="flex-item">
                    <Typography variant="h4">{tip.week}</Typography>
                  </Box>
                </Box>
                <Typography variant="h4">{tip.tip_title}</Typography>
                <Typography variant="body1">{tip.tip_description}</Typography>
                <Button
                  type="button"
                  className="btn_card d-none"
                  onClick={async () => {
                    setVideoActivity(`${currentYear}_${tip.id}_data_protection_click_activity`);
                    window.open(tip.video, '_blank'); 
                  }}
                  disabled={!tip.video}
                >
                  View Video
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DataProtectionTabs;
